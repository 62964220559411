<template>
  <div class="left-right-wrapper">
    <LeftRight
      :leftColor="block.leftColor"
      :rightColor="block.rightColor"
      :threhshold="block.threshold"
      @change="UpdateLeftRight($event)"
      :debug="ShowDebugger"/>
  </div>
</template>

<script>
import LeftRight from '@/components/externals/LeftRight.vue';
export default {
  components: {
    LeftRight
  },
  props: {
    block:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lastSent: null,
    };
  },
  computed: {},
  methods: {
    UpdateLeftRight(data) {
      if(data.direction == this.lastSent) return

      if (data.direction == 'neutral' &&  !this.block.sendNeutral) return

      this.$socket.client.emit('gameMessage', {
        type: 'controllerData',
        controllerType: 'leftright',
        name: this.block.blockName,
        input: {
          direction: data.direction
        }
      })
      this.lastSent = data.direction      
    }
  },
  mounted() {
    //
  },
};
</script>
