<template>
  <gyro-access @moved="UpdateGyro" v-slot="gyro" ref="myGyro">
    <div v-if="!requested" class="tutorial p-5">
      <img src="/assets/external/leftright/tutorial.svg" />
      <b-button
        type="is-primary"
        size="is-medium"
        :label="$t('Activar sensores')"
        expanded
        @click="RequestGyro()"
      />
    </div>

    <div v-else-if="showNoGyroTutorial" class="tutorial p-5">
      <img src="/assets/external/leftright/nogyro-tutorial.svg" />
      <p class="is-size-6 has-text-black-ter has-text-weight-bold">
        {{ $t("exp_external.leftRightNoSensors") }}
      </p>
      <b-button
        type="is-primary"
        size="is-medium"
        :label="$t('Jugar')"
        expanded
        @click="playWithButtons = true"
      />
    </div>

    <div v-else class="arrows">
      <div
        class="arrow left"
        :class="{ active: arrowState == 'left' }"
        :style="{ '--activeColor': leftColor }"
      >
        <img
          src="/assets/external/leftright/left.svg"
          @mousedown="touchArrow('left')"
          @touchstart="touchArrow('left')"
          @mouseup="releaseArrow()"
          @touchend="releaseArrow()"
        />
      </div>
      <div
        class="arrow right"
        :class="{ active: arrowState == 'right' }"
        :style="{ '--activeColor': rightColor }"
      >
        <img
          src="/assets/external/leftright/right.svg"
          @mousedown="touchArrow('right')"
          @touchstart="touchArrow('right')"
          @mouseup="releaseArrow()"
          @touchend="releaseArrow()"
        />
      </div>
    </div>
    <code v-if="debug" class="debugger is-size-7 p-4">
      {{ gyro }}, {{ $t("exp_external.leftRightDirection") }}: {{ arrowState }},
      {{ $t("exp_external.leftRightRotation") }}:
      {{ Math.round(rotation * 100) / 100 }},
    </code>
  </gyro-access>
</template>

<script>
import GyroAccess from "../GyroAccess";

export default {
  components: {
    GyroAccess,
  },
  props: {
    leftColor: {
      type: String,
      default: "#FF5555",
    },
    rightColor: {
      type: String,
      default: "#55FF00",
    },
    threhshold: {
      type: Number,
      default: 0.5,
    },
    debug: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      arrowState: "neutral",
      direction: "neutral",
      buttonTouched: false,
      rotation: 0,
      threshold: 0.2,
      requested: false,
      playWithButtons: false,
    };
  },
  computed: {
    showNoGyroTutorial() {
      return !this.$refs.myGyro.dataStarted && !this.playWithButtons;
    },
  },
  methods: {
    RequestGyro() {
      this.requested = true;
      this.$refs.myGyro.RequestGyro();
    },
    UpdateGyro(data) {

      if(this.buttonTouched) return;

      var b = Math.abs(data.orientation.beta) / 90;
      if (b > 1) b = 2 - b;

      var g = data.orientation.gamma / 90;
      if (Math.abs(data.orientation.beta) > 90) g = -g;

      this.rotation = g / Math.max(0.25, b);

      // NEUTRAL
      if (Math.abs(this.rotation) < this.threshold) {
        this.arrowState = "neutral";

        if (this.direction != "neutral") {
          this.direction = "neutral";
          this.emitDirection();
        }

        return;
      }

      // // IZQUIERDA
      if (this.rotation < -this.threshold) {
        this.arrowState = "left";

        if (this.direction != "left") {
          this.direction = "left";
          this.emitDirection();
        }

        return;
      }

      // // DERECHA
      if (this.rotation > this.threshold) {
        this.arrowState = "right";

        if (this.direction != "right") {
          this.direction = "right";
          this.emitDirection();
        }

        return;
      }
    },
    touchArrow(direction) {
      if(this.buttonTouched) return;
      this.buttonTouched = true;
      this.arrowState = direction;
      console.log("No hay giro, envia boton");
      this.direction = direction;
      this.emitDirection();
    },
    releaseArrow() {
      if(!this.buttonTouched) return;
      this.buttonTouched = false;
      this.arrowState = "neutral";
      this.direction = "neutral";
      this.emitDirection();
    },
    emitDirection() {
      this.$emit("change", {
        direction: this.direction,
      });
    },
  },
  mounted() {
    this.emitDirection();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.gyroAccess .tutorial {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  overflow-y: auto;
  z-index: 0;
}

.gyroAccess {
  align-items: center;
  justify-content: center;
  height: 100%;
}

.arrows {
  outline: solid 1px #d1d1d1;
  width: 100%;
  height: 100%;
  display: flex;

  & > div {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .arrow {
    transition: all 50ms;

    img {
      width: 50%;
      max-width: 200px;
    }

    &.right {
      background-color: rgba($black, 0.15);
    }

    &.left.active {
      background-color: var(--activeColor);
    }

    &.right.active {
      background-color: var(--activeColor);
    }
  }
}

.debugger {
  width: 100%;
  color: $info;
  border-top: solid 1px $info;
}
</style>
